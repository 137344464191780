<template>
	<div>
<!--		<div style="text-align: center;margin: 20px 0;color: red" @click="validate">&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;验证附属商品Form&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;</div>-->
    <div class="dependentsList" v-for="(itemFrom,index) in dependentsFormList " :key="index">
			<el-form :model="itemFrom" :rules="dependentsRules" :ref="'taskTwoFormRules'+index" size="medium"
				label-width="130px">
				<el-form-item label-width="0">
					<div>
						附加商品{{index | fillerIndex}}（已添加商品宝贝请点击此处 <el-button class="choice_but"
							@click="subChoiceBut(index)">选择商品宝贝</el-button> ）
					</div>
				</el-form-item>
				<el-form-item label="商品链接：" prop="goodsLink" >
					<div style="display: flex;">
						<el-input v-model="dependentsFormList[index].goodsLink" placeholder="用于平台审核任务,买家不可见"
							style="width: 600px;">
						</el-input>
						<el-button type="primary" @click="automatic" style="margin-left: 5px;">自动获取</el-button>
					</div>
				</el-form-item>
				<el-form-item label="商品名称：" prop="goodsTitle" >
					<div style="display: flex;">
						<el-input v-model="dependentsFormList[index].goodsTitle" placeholder="用于平台审核任务,买家不可见"
							style="width: 700px;">
						</el-input>
					</div>
				</el-form-item>
				<el-form-item label="商品主图：" prop="goodsPicture" >
					<div>确保与搜索展示的图片一致，支持PNG/JPEG/JPG格式文件，该图片用于买家接单页面的主图宝贝</div>
					<div>
						<sb-upload :imgStyle="{height:'93px'}" @handleSuccess="handleSuccess($event,index)" :imageUrl="dependentsFormList[index].goodsPicture" type="5">
						</sb-upload>
					</div>
				</el-form-item>
				<el-form-item label="颜色规格尺寸：" prop="goodsSeamen">
					<el-input
							v-model="dependentsFormList[index].goodsSeamen"
							style="width: 180px"
							placeholder="买家下单时选择的颜色、尺码、规格">
					</el-input>
				</el-form-item>
<!--				<el-form-item label="颜色规格尺寸：" prop="specDesc">-->
<!--					<el-radio-group v-model="dependentsFormList[index].specFlag" @change="radioChange">-->
<!--						<el-radio label="0" name="任意规格">-->
<!--							<span>任意规格</span>-->
<!--						</el-radio>-->
<!--						<el-radio label="1" name="自定义规格">-->
<!--							<span>自定义规格</span>-->
<!--							<el-input v-model="dependentsFormList[index].specDesc" class="specFlag"-->
<!--								:disabled="dependentsFormList[index].specFlag == 0"-->
<!--								placeholder="买家下单时选择的颜色、尺码、规格"></el-input>-->
<!--						</el-radio>-->
<!--					</el-radio-group>-->
<!--				</el-form-item>-->

				
				<el-form-item label="展示价格："  prop="displayPrice">
					<div style="display: flex;">
						<el-input v-model="dependentsFormList[index].displayPrice" placeholder="请输入店铺名称"
							style="width: 180px;margin-right: 15px;"></el-input>元
					</div>
				</el-form-item>
        <el-form-item label="单件价格："  prop="unitPrice">
          <div style="display: flex;">
            <el-input v-model="dependentsFormList[index].unitPrice" placeholder="请输入单件价格" @change="unitPriceChange(index)"
                      style="width: 180px;margin-right: 15px;"></el-input>元
          </div>
        </el-form-item>
				<el-form-item label="每单拍："  prop="buyAmount">
						<div>
							<el-input v-model="dependentsFormList[index].buyAmount"  @change="amountChange(index)"
								style="width: 180px;margin-right: 15px;"></el-input>件
						</div>
				</el-form-item>
        <el-form-item label="拍下价格："  prop="payablePrice">
          <div style="display: flex;justify-content: space-between;">
            <div>
              <el-input v-model="dependentsFormList[index].payablePrice" placeholder="请输入拍下价格"
                        style="width: 180px;margin-right: 15px;"></el-input>元
            </div>
              <div>
                <el-button size="small" plain @click="addDependents"><i
                    class="el-icon-plus"></i>添加附加商品</el-button>
                <el-button size="small" plain @click="delDependents(index)"><i
                    class="el-icon-minus"></i>删除附加商品</el-button>
              </div>
          </div>
        </el-form-item>
			</el-form>
		</div>

    <goods-list-model ref="subGoodsListModel" @check="subGoodscheck"  ></goods-list-model>
	</div>
</template>

<script>
	import GoodsListModel from '@/views/goodsManagement/goodsListModel';
	import SbUpload from '@/components/upload/upload.vue';
	import {isInt, price} from "../../../tools/validate";
	export default {
		data() {
			return {
				dependentsRules: {
					goodsLink:[
						{required:true,message:'请输入商品链接',trigger:['blur']}
					],
					goodsTitle:[
						{required:true,message:'请输入商品名称',trigger:['blur']}
					],
					goodsPicture:[
						{required:true,message:'请上传商品主图',trigger:['change']}
					],
					goodsSeamen:[
						{required:true,message:'颜色规格尺寸',trigger:['blur']}
					],
					displayPrice:[
						{required:true,validator:price,trigger:['blur']}
					],
					unitPrice:[
						{required:true,validator:price,trigger:['blur']}
					],
					buyAmount:[
						{required:true,validator:isInt,trigger:['blur']}
					],
				},

				selectIndex:'',
				dependentsFormList: [],
			}
		},
		props:{
			shopId:{

			},
      rules:{
        type:[Object]
      },
      cacheDependentsFormList:{
      },
		},
		methods: {
      clear(){
        this.dependentsFormList = []
      },
      resetFields(){
        const length = this.dependentsFormList.length;
        for (let index=0;index<length;index++){
          this.$refs['taskTwoFormRules'+index][0].resetFields()
        }
      },
      amountChange(index){
        this.dependentsFormList[index].payablePrice = this.dependentsFormList[index].buyAmount * this.dependentsFormList[index].unitPrice
      },
      unitPriceChange(index){
        this.dependentsFormList[index].payablePrice = this.dependentsFormList[index].buyAmount * this.dependentsFormList[index].unitPrice
      },
			validate(){
				const length = this.dependentsFormList.length;
				let flag = true;
				for (let index=0;index<length;index++){
					this.$refs['taskTwoFormRules'+index][0].validate(validator=>{
						if(!validator){
							flag =validator;
						}
					})
				}
				if(flag){
					this.$emit('attGoodsValidateSuccess',{att:this.dependentsFormList})
				}
				return flag
			},
			subChoiceBut(index){
				this.selectIndex = index;
				this.$refs.subGoodsListModel.open(this.shopId);
			},
			handleSuccess: function(resp,index) {
				console.log('图片上传成功');
				console.log(resp);
        console.log()
				if(resp.code == 200){
					this.dependentsFormList[index].goodsPicture = resp.data.hash;
					this.$refs['taskTwoFormRules'+index][0].validateField('goodsPicture')
				}
			},
			automatic(){
				
			},
			radioChange(){
				
			},
			subGoodscheck(row){
				//把宝贝里面的数据赋值到表单
				console.log(row)
				Object.keys(this.returnDependentsForm()).forEach(key=>{
					this.dependentsFormList[this.selectIndex][key] = row[key];
				});
				//因为数据不一样，重新赋值。
				this.dependentsFormList[this.selectIndex].unitPrice = row.payablePrice;
				this.dependentsFormList[this.selectIndex].buyAmount = 1;
				this.$refs['taskTwoFormRules'+this.selectIndex][0].validate()
				//清空标识
				this.selectIndex = '';
			},
			returnDependentsForm: function() {
				console.log('添加附加商品的form表单');

				return {
					goodsLink: '',
					goodsTitle:'',
					goodsPicture:'',
					goodsSeamen:'',
					buyAmount:'',
					unitPrice:'',
          payablePrice:'',
					displayPrice:'',
				}
			},
			delDependents: function(index) {
				console.log('删除第' + index + '个 附加商品');
				this.dependentsFormList.splice(index, 1)
			},
			addDependents: function() {
				console.log('添加附加商品');
				if (this.dependentsFormList.length == this.rules.attachNum) {
					this.$mes({
						message: `附加商品不能超过${this.rules.attachNum}个`,
						type: 'error'
					})
					return false;
				}
				this.dependentsFormList.push(this.returnDependentsForm())
			},
		},
		components: {
			SbUpload,
			GoodsListModel,
		},
		computed: {
			// name() {
			// 	return this.data
			// }
		},
		watch: {
      cacheDependentsFormList: {
        handler(newV) {
          this.dependentsFormList = new Array()
          newV.forEach(item => {
            let map = {}
            for (let key in this.returnDependentsForm()) {
              map[key] = item[key]
            }
            this.dependentsFormList.push(map)
          })
          console.log(this.dependentsFormList)
        },
        immediate:true,
      }
		},
		filters: {
			fillerIndex: function(index) {
				var indexName = ''
				switch (index) {
					case 0:
						indexName = '一'
						break;
					case 1:
						indexName = '二'
						break;
					case 2:
						indexName = '三'
						break;
					case 3:
						indexName = '四'
						break;
					case 4:
						indexName = '五'
						break;
					default:
						break;
				}
				return indexName
			}
		},

		
		
	}
</script>

<style lang="less" scoped>
	
	.dependentsList {
		background-color: #fafafa;
		border: 1px solid #eee;
		padding: 30px 20px;
		margin-bottom: 25px;
	}
</style>
