<template>
	<div class="goodsListWrap">
		<el-dialog title="选择宝贝" :visible.sync="dialogVisible" :before-close="handleClose" :destroy-on-close="true">
			<div>
				<el-form ref="form" :model="listForm" :inline="true" size="medium">
					<!--					<el-form-item label="商品名称">-->
					<!--						<el-input v-model="listForm.goodsTitle" placeholder="请输入商品名称" style="width: 200px;"></el-input>-->
					<!--					</el-form-item>-->
					<el-form-item label="宝贝ID">
						<el-input v-model="listForm.goodsNo" placeholder="请输入宝贝ID" clearable style="width: 200px;">
						</el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="checkList">搜索</el-button>
					</el-form-item>
				</el-form>
				<el-table :data="tableData" style="width: 100%" border stripe max-height="500">
					<el-table-column type="index">
					</el-table-column>
					<el-table-column prop="goodsPicture" label="商品主图">
						<template slot-scope="scope">
							<sb-image :imgStyle="{margin: '0 auto'}" height="100px" width="100px"
								:src="scope.row.goodsPicture"></sb-image>
						</template>
					</el-table-column>
					<el-table-column prop="goodsTitle" label="商品名称">
					</el-table-column>
					<el-table-column prop="shopName" label="店铺名称">
					</el-table-column>
					<el-table-column prop="payablePrice" label="实际支付金额">
					</el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<div class="link" @click="check(scope.row)">选择宝贝</div>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination background :page-size="15" layout="total,prev, pager, next" :total="total"
					@size-change="handleSizeChange" @current-change="handleCurrentChange">
				</el-pagination>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	// beforeCreate created beforeMount mounted 都不会触发。
	import SbImage from '../../components/image/image.vue';
	import {
		GOODSINFOLIST
	} from '../../api/base'
	export default {
		data() {
			return {
				listForm: {
					goodsNo: '',
					shopId: '',
					goodsTitle: '',
					pageIndex: 1,
					pageSize: 15,
				},
				tableData: [],
				total: 0,
				dialogVisible: false,
			}
		},
		props: {

		},
		methods: {
			handleSizeChange(val) {
				this.listForm.pageIndex = 1;
				this.listForm.pageSize = val;
				this.getList()
			},
			handleCurrentChange(val) {
				this.listForm.pageIndex = val;
				this.getList()
			},
			checkList() {
				this.listForm.pageIndex = 1;
				this.getList()
			},
			getList(){
				this.tableData = [];
				this.$axios.get(GOODSINFOLIST, this.listForm).then(resp => {
					if (resp.code == 200) {
						this.tableData = resp.data.data;
						// this.tableData = [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},]
						this.total = resp.data.totalRow;
					}
				}).catch(err => {
					console.log(err)
				})
			},
			open(shopId) {
				if (!shopId) return this.$mes({
					message: '请先选择店铺',
					type: 'warning'
				})
				this.listForm.shopId = shopId;
				this.listForm.goodsTitle = '';
				this.listForm.goodsNo = '';
				this.listForm.unitPrice = this.listForm.payablePrice;

				this.checkList();
				this.dialogVisible = true;
			},
			handleClose() {
				this.dialogVisible = false;
				this.$emit('handleClose')
			},
			check(row) {
				this.dialogVisible = false;
				const copyRow = Object.assign({}, row)
				for (let key in copyRow) {
					if (copyRow[key] === undefined || copyRow[key] === null) delete copyRow[key]
				}

				copyRow.unitPrice = copyRow.payablePrice;
				copyRow.goodsSeamen = '';
				this.$emit('check', copyRow)
			},
		},
		components: {
			SbImage,
		},
		activated: {
			// keep-alive组件 激活 时调用。
			//该钩子在服务器端渲染期间不被调用。

		},
		deactivated: {
			//keep-alive组件  停用 时调用。
			//该钩子在服务器端渲染期间不被调用。
		},
		watch: {
			// data(newValue, oldValue) {

			// }
		},
		filters: {
			// fnName: function(value) {
			// 	return value;
			// }
		},
		beforeCreate() {

		},
		created() {

		},
		beforeMount() {

		},
		mounted() {

		},
		beforeUpdate() {

		},
		updated() {

		},
		beforeDestroy() {

		},
		destroyed() {

		},
		computed: {
			// name() {
			// 	return this.data
			// }
		},

	}
</script>

<style lang="less" scoped>
	.link {
		color: #00f;
		cursor: pointer;
	}

	.goodsListWrap /deep/ .el-table__header-wrapper th {
		background-color: #F4F5F8 !important;
	}
</style>
