<template>
	<div>
		<!-- 平台与任务类型 -->
		<platform-and-tasktype
				ref="taskForm"
				:rules="rules"
        :cacheForm="cacheForm"
        @success="platformSuccess"
				@platformChange="platformChange"
				@shopNameChange="shopNameChange"
		>
		</platform-and-tasktype>
    <!--    <span @click="validateAll">&#45;&#45;&#45;&#45;platform-and-tasktype-&#45;&#45;&#45;&#45;</span>-->
<!--     商品基础信息 -->
		<goods-base
        :isShowDate="isShowDate"
        @showStatus="(flag)=>{isShowDate = flag}"
        :cacheForm="cacheForm"
        :rules="rules"
        :shopId="taskVo.shopId"
        :platformId="taskVo.platformId"
        ref="goods"
        @successValidate="successValidate">
		</goods-base>
<!--    <span @click="validateAll">&#45;&#45;&#45;&#45;platform-and-tasktype-&#45;&#45;&#45;&#45;</span>-->
    <div style="text-align: center;margin-bottom: 20px;">
			<el-button type="primary" size="medium" @click="validateAll" style="width:228px;height: 50px;">下一步
			</el-button>
		</div>
	</div>
</template>

<script>
	import PlatformAndTasktype  from './component/platformAndTasktype';
	import GoodsBase  from './component/goodsbase';
	import {TASKRULES} from '../../api/base';

	const taskVo = {
		platformId:'',
		rebateMethod:'',
		shopId:'',
		typeCode:'',
	}
	export default {
		data() {
			return {
				taskVo,
				rules:{},
				totalFormObj:{},
        cacheForm:null,
        isShowDate:true,
			}
		},

		components: {
			PlatformAndTasktype,
			GoodsBase,
		},
		methods: {
      platformSuccess(form){
        console.log(form)
        Object.assign(this.taskVo,form)
      },
			// {taskAttachGoodsVo,baseGoods}
			successValidate({att,baseGoods}){
				this.totalFormObj.taskAttachGoodsVo = att;
				this.totalFormObj.baseGoods = baseGoods;
				this.totalFormObj.taskVo = this.taskVo;
			},
			validateAll(){
				const isGoods = this.$refs.goods.validateAll();
				const taskForm = this.$refs.taskForm.validateAll();
				//验证主商品 和 附属商品 的FORM表单
				if(isGoods&&taskForm){
					console.log('已经全部验证通过');
					this.$store.commit('setting/setOneTaskInfo', this.totalFormObj)
					// this.$store.getters['setting/oneTaskInfo']
          this.$destroy()
          const taskId = this.$route.query.taskId
          if(taskId){
            this.$router.push('/pageIndex/taskTwo?taskId='+taskId+'&type=edit')
          }else{
            this.$router.push('/pageIndex/taskTwo?type=add')
          }
				}
			},
			shopNameChange(id){
				this.taskVo.shopId = id;
			},
			platformChange(id){
        this.taskVo.platformId = id;
				this.taskVo.shopId = '';
				this.taskVo.typeCode = '';

			},
			getTaskRules(callback){
				this.$axios.get(TASKRULES).then(resp=>{
					if(resp.code == 200){
						this.rules = resp.data;
            callback&&callback()
					}
				})
			},

		},
		watch: {
			// data(newValue, oldValue) {

			// }
		},
		mounted() {
      console.log('mounted')
      this.getTaskRules(()=>{
        const oneTaskInfo = this.$store.getters['setting/oneTaskInfo'];
        this.cacheForm = oneTaskInfo || null;
        this.isShowDate = oneTaskInfo?true:false;
      })

		},
		computed: {
			// name() {
			// 	return this.data
			// }
		},

	}
</script>

<style lang="less" scoped>
	

	

	.shopNameForm {
		margin-bottom: 20px;
	}



	.choice_but {
		width: 120px;
		height: 24px;
		padding: 0;
		background: #fff1ed;
		border: 1px solid #ff714b;
		border-radius: 2px;
		color: #ff714b;
	}

	

	.box-card {
		width: 980px;
		margin-bottom: 20px;
	}
</style>
