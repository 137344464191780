<template>
    <div style="display: flex;align-items: center">
        <el-upload
                class="avatar-uploader"
                :action="uploadUrl"
                :show-file-list="false"
                :on-success="handleSuccess1"
                :on-change="onChange1"
                :data="{nameSpaces:uploadTypeList[4]}"
                accept="image/jpeg,image/jpg,image/png"
                :headers="{'x-auth-token':$store.getters['setting/userToken']}"
                :before-upload="beforeAvatarUpload">
            <!-- <img v-if="imageUrlCopy" :src="imageUrlCopy" class="avatar"> -->
            <div v-loading="loading1">
                <div class="no-upload hoverHand">
                    <div class="newBox">
                        <el-image v-if="copyGoodsPicture&&!flag" :src="copyGoodsPicture"  fit="fit"></el-image>
                        <el-image v-else-if="copyWatermarkPicture&&flag" :src="copyWatermarkPicture"  fit="fit"></el-image>
                        <i  v-else class="iconfont icon-B"></i>
                    </div>
                </div>
            </div>
        </el-upload>
        <el-tooltip class="item" effect="dark" placement="top" style="margin: 0 15px;">
            <template slot="content" v-if="disabled">
                请先上传商品主图
            </template>
            <div style="display: flex;flex-direction: column;align-items: center">
                <el-switch v-model="flag" :disabled="disabled" @change="switchChange"></el-switch>
                <div>是否显示水印</div>
            </div>
        </el-tooltip>

        <el-upload
                v-if="flag"
                class="avatar-uploader"
                :action="upload"
                :show-file-list="false"
                :on-success="handleSuccess2"
                :on-change="onChange2"
                :data="{nameSpaces:uploadTypeList[5],wasChanged,}"
                accept="image/jpeg,image/jpg,image/png"
                :headers="{'x-auth-token':$store.getters['setting/userToken']}"
                :before-upload="beforeAvatarUpload">
            <!-- <img v-if="imageUrlCopy" :src="imageUrlCopy" class="avatar"> -->
            <div v-loading="loading2">

                <div class="no-upload hoverHand">
                    <i class="iconfont icon-B"></i>
                </div>

            </div>
        </el-upload>
    </div>
</template>

<script>
    // import SbUpload from '../../../components/upload/upload.vue';
    import {UPLOADWATERiMG,UPLOADiMG} from '../../../api/base'

    export default {
        name: "uploadWaterImg",
        data() {
            return {
                uploadUrl: process.env.VUE_APP_API_BASE_URL + '' +UPLOADWATERiMG,
                upload: process.env.VUE_APP_API_BASE_URL + '' +UPLOADiMG,
                uploadTypeList:{},
                loading1:false,
                loading2:false,
                imageUrlCopy:'',
                flag:false,
                disabled:true,
                wasChanged:'',
                copyGoodsPicture:'',
                copyWatermarkPicture:'',
            }
        },
        watch:{
            goodsPicture:{
                handler(newVal){
                    if(newVal){
                        this.disabled = false;
                        this.copyGoodsPicture = process.env.VUE_APP_FILE_URL+'/'+newVal;
                        this.wasChanged  = process.env.VUE_APP_FILE_URL+'/'+newVal;
                    }else{
                        this.disabled = true;
                    }
                },
                immediate:true
            },
            watermarkFlag:{
                handler(newVal){
                    if(newVal){
                        this.flag = true;
                    }else{
                        this.flag = false;
                    }
                },
                immediate:true
            },
            watermarkPicture:{
                handler(newVal){
                    if(newVal){
                        this.copyWatermarkPicture= process.env.VUE_APP_FILE_URL+'/'+newVal
                    }
                },
                immediate:true
            },
        },
        props:{
            watermarkPicture:{

            },
            watermarkFlag:{

            },
            goodsPicture:{

            },
        },
        methods: {
          clear(){
            this.copyGoodsPicture = ''
            this.copyWatermarkPicture = ''
            this.flag = false;
          },
          switchChange(flag){
              if(flag){
                  this.copyGoodsPicture =   process.env.VUE_APP_FILE_URL+'/'+this.watermarkPicture;
              }else{
                  this.copyGoodsPicture =  process.env.VUE_APP_FILE_URL+'/'+this.goodsPicture;
                  console.log(this.copyGoodsPicture)
              }

          },
          onChange1(data){
              if(data.status =='uploading'||data.status =='ready'){
                  this.loading1 = true;
              }else{
                  this.loading1 = false;
              }
          },
          onChange2(data){
              if(data.status =='uploading'||data.status =='ready'){
                  this.loading2 = true;
              }else{
                  this.loading2 = false;
              }
          },
          handleSuccess1(res) {
              if(res.code != 200){
                  return  this.$mes({message: res.msg, type: 'error'})
              }
              this.$emit('handleSuccess1', res)
          },
          handleSuccess2(res) {
              if(res.code != 200){
                 return  this.$mes({message: res.msg, type: 'error'})
              }
              this.$emit('handleSuccess2', res)
          },
          beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 4;

            if (!isJPG) {
                this.$mes({message: '上传图片只能是 JPG，JPEG，PNG 格式!', type: 'error'})
            }
            if (!isLt2M) {
                this.$mes({message: '上传图片大小不能超过 4MB!', type: 'error'})
            }
            return isJPG && isLt2M;
          }
        },
        components: {

        },
        mounted() {
            this.uploadTypeList = this.$store.state.uploadTypeList;
        }
    }
</script>

<style scoped lang="less">
    .newBox{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        /deep/ .el-image{
            line-height: initial;
        }
    }
    .no-upload{
        position: relative;
        border: 2px dashed rgb(221, 221, 221);
        border-radius: 6px;
        cursor: pointer;
        overflow: hidden;
        display: inline-block;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .no-upload {
        font-size: 28px;
        color: rgb(221, 221, 221);
        width: 93px;
        height: 93px;
        line-height: 93px;
        text-align: center;
    }

    .avatar {
        display: block;
    }

    .icon-B {
        color: rgb(221, 221, 221);
        font-size: 40px;
    }
</style>