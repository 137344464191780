<template>
	<div>
		<el-dialog
				:title="title"
				:visible.sync="dialogVisible"
				width="951"
				:before-close="handleClose"
				:close-on-click-modal="false">

			<div>
				<el-form ref="ruleForm" :model="form" label-width="100px" :rules="formRules" size="medium">
					<el-form-item label="平台：" prop="platformId">
						<el-select v-model="form.platformId" placeholder="请选择平台">
							<el-option
									:label="item.platformName"
									:value="item.platformId"
									v-for="(item,index) in platformLIst "
									:key="index">
							</el-option>

						</el-select>
					</el-form-item>
					<el-form-item label="店铺名称：" prop="shopName">
						<el-input v-model="form.shopName" placeholder="请输入店铺名称" style="width: 500px;"></el-input>
					</el-form-item>
					<el-form-item label="店铺链接：" prop="shopUrl">
						<el-input v-model="form.shopUrl" placeholder="请输入店铺链接" style="width: 500px;"></el-input>
					</el-form-item>
					<!--					<el-form-item label="发货地址：" required prop="infoAdress">-->
					<!--						<sb-adress @change="adressChange" style="margin-bottom: 20px;"></sb-adress>-->
					<!--						<el-input-->
					<!--								v-model="form.infoAdress"-->
					<!--								style="margin-bottom: 20px;width: 500px;"-->
					<!--								placeholder="详细地址用于发空包,请填写商品发货地址">-->
					<!--						</el-input>-->
					<!--					</el-form-item>-->
					<el-form-item label="店铺旺旺：" prop="shopNickName">
						<el-input v-model="form.shopNickName" placeholder="请输入店铺名称" style="width: 500px;"></el-input>
					</el-form-item>
					<el-form-item label="后台截图：" prop="shopWebsite">
						<sb-upload @handleSuccess="handleSuccess" :type="7" :imageUrl="form.shopWebsite" :imgStyle="{width:'100px',height:'100px'}"></sb-upload>
					</el-form-item>
					<el-form-item label="描述信息：" prop="memo">
						<el-input
								v-model="form.memo"
								type="textarea"
								:rows="2"
								placeholder="请输入描述信息"
								style="width: 500px;">

						</el-input>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="save" :loading="loading">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	// beforeCreate created beforeMount mounted 都不会触发。
	import {PLATFORM, SHOP} from '../../api/base'


	// import SbAdress from '../../components/adress/adress.vue'
	import SbUpload from '../../components/upload/upload.vue'

	export default {
		data() {
			return {
				dialogVisible: false,
				loading: false,
				platformLIst: [],
				form: {
					platformId: "",
					shopName: "",
					shopUrl: "",
					shopNickName: "",
					shopWebsite: "",
					memo: "",
					shopId: '',

				},
				formRules: {
					platformId: [{
						required: true,
						message: '请选择平台',
						trigger: 'change'
					},],
					shopName: [{
						required: true,
						message: '请填上店铺名称',
						trigger: 'blur'
					}],
					shopUrl: [{
						required: true,
						message: '请填上店铺链接',
						trigger: 'blur'
					}],
					shopNickName: [{
						required: true,
						message: '请填上店铺旺旺',
						trigger: 'blur'
					}],
					shopWebsite: [{
						required: true,
						message: '请上传店铺截图',
						trigger: 'change'
					}],
				}
			}
		},
		props: {
			title: {
				type: String,
				default: () => {
					return ''
				}
			},
			name: {
				type: Array,
				default: () => {
					return []
				}
			}
		},
		methods: {
			getPlatformList() {
				this.$axios.get(PLATFORM).then(resp => {
					console.log(resp)
					if (resp.code == 200) {
						this.platformLIst = resp.data
					}
				})
			},
			handleSuccess(res) {
				console.log('上传图片成功返回')
				if (res.code == 200) {
					this.form.shopWebsite = res.data.hash;
				}
			},
			adressChange(adressData) {
				console.log(adressData)
			},
			save() {
				this.$refs.ruleForm.validate((validate) => {
					// 验证通过
					if (validate) {
						this.loading = true;

						if (this.title == '绑定店铺') {
							this.$axios.post(SHOP, this.form).then(resp => {
								console.log(resp)
								this.loading = false;
								if (resp.code == 200) {
									this.$mes({message: resp.msg})
									this.dialogVisible = false;
									this.$emit('successHandel')
								} else {
									this.$mes({message: resp.msg, type: 'warning'})
								}
							}).catch(()=>{
                this.loading = false;
              })
						} else {
							const shopId = this.form.shopId
							this.$axios.put(`${SHOP}/${shopId}`, this.form).then(resp => {
								console.log(resp);
								this.loading = false;
								if (resp.code == 200) {
									this.$mes({message: resp.msg})
									this.dialogVisible = false;
									this.$emit('successHandel')

								} else {
									this.$mes({message: resp.msg, type: 'warning'})
								}
							}).catch(()=>{
                this.loading = false;
              })
						}
					}
				})
			},
			handleClose() {
				this.dialogVisible = false;
				this.$emit('handleClose');

			},
			open(row) {
				this.dialogVisible = true;
				this.loading = false;

				this.getPlatformList();
				this.$nextTick(() => {
					if (row) {
						this.$refs.ruleForm.resetFields()

						Object.keys(row).forEach(item => {
							if (this.form[item] != undefined) {
								this.form[item] = row[item]

							}
						})
					} else {
						this.$refs.ruleForm.resetFields()
					}
				})
			},
			stop() {
				this.dialogVisible = false;
			}
		},
		components: {
			// SbAdress,
			SbUpload,
		},
		activated: {
			// keep-alive组件 激活 时调用。
			//该钩子在服务器端渲染期间不被调用。

		},
		deactivated: {
			//keep-alive组件  停用 时调用。
			//该钩子在服务器端渲染期间不被调用。
		},
		watch: {
			// data(newValue, oldValue) {

			// }
		},
		filters: {
			// fnName: function(value) {
			// 	return value;
			// }
		},
		beforeCreate() {

		},
		created() {

		},
		beforeMount() {

		},
		mounted() {

		},
		beforeUpdate() {

		},
		updated() {

		},
		beforeDestroy() {

		},
		destroyed() {

		},
		computed: {
			// name() {
			// 	return this.data
			// }
		},

	}
</script>

<style lang="less" scoped>
	/deep/ .el-select .el-input.is-focus .el-input__inner{
		border-color: @primaryColorQian;
	}
	/deep/ .el-input__inner:focus {
		border-color: @primaryColorQian;
	}
</style>
