<template>
	<div>
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<div>
					商品基础信息（已添加商品宝贝请点击此处
					<el-button class="choice_but" @click="baseChoiceBut">选择商品宝贝</el-button>
					）
				</div>
			</div>
			<div>
				<!-- 主商品 -->
				<el-form :model="baseGoodsForm" :rules="baseGoodsFormRules" ref="baseGoodsForm" label-width="130px" size="medium"
					class="shopNameForm">
					<el-form-item label="商品链接：" prop="goodsLink" >
						<div style="display: flex;">
							<el-input v-model="baseGoodsForm.goodsLink" style="width: 600px;" placeholder="请输入商品链接"></el-input>
							<el-button type="primary" @click="automatic" style="margin-left: 5px;">自动获取</el-button>
						</div>
					</el-form-item>
					<el-form-item label="商品名称：" prop="goodsTitle" >
						<div style="display: flex;">
							<el-input v-model="baseGoodsForm.goodsTitle" style="width: 700px;" placeholder="请输入商品名称"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="商品主图：" prop="goodsPicture" >
						<div>确保与搜索展示的图片一致，支持PNG/JPEG/JPG格式文件，该图片用于买家接单页面的主图宝贝</div>
						<div>
<!--							<sb-upload @handleSuccess="handleSuccess" :imageUrl="baseGoodsForm.goodsPicture"></sb-upload>-->
              <upload-water-img
									:watermarkPicture="baseGoodsForm.watermarkPicture"
									:watermarkFlag="baseGoodsForm.watermarkFlag"
									:goodsPicture="baseGoodsForm.goodsPicture"
									@handleSuccess2="handleSuccess2"
									@handleSuccess1="handleSuccess1"
                  ref="uploadWaterImg"
							>
							</upload-water-img>
						</div>
					</el-form-item>
<!--					<el-form-item prop="platform">-->
<!--						<div>-->
<!--							<el-checkbox v-model="baseGoodsForm.addGive"> 是否赠送礼品</el-checkbox>-->
<!--							<span style="color: #999;">（上传礼品图让买家知悉收到货的礼品，可不勾选）</span>-->
<!--						</div>-->
<!--						<div v-show="baseGoodsForm.addGive">-->
<!--							<sb-upload @handleSuccess="handleSuccess"></sb-upload>-->
<!--						</div>-->
<!--					</el-form-item>-->
					<el-form-item label="颜色规格尺寸：" prop="goodsSeamen">
						<el-input
								v-model="baseGoodsForm.goodsSeamen"
								style="width: 180px"
								placeholder="买家下单时选择的颜色、尺码、规格">
						</el-input>
					</el-form-item>

		
					<el-form-item label="展示价格："  prop="displayPrice">
						<div style="display: flex;">
							<el-input v-model="baseGoodsForm.displayPrice" placeholder="请输入展示价格"
								style="width: 180px;margin-right: 15px;"></el-input>元
						</div>
					</el-form-item>

          <el-form-item label="单件价格："  prop="unitPrice">
            <div style="display: flex;">
              <el-input v-model="baseGoodsForm.unitPrice" placeholder="请输入单件价格" @change="unitPriceChange"
                        style="width: 180px;margin-right: 15px;"></el-input>元
              <!-- <div class="linkText" @click="norm">基础佣金收费标准</div> -->

            </div>
          </el-form-item>
		
					<el-form-item label="每单拍："  prop="buyAmount">
						<div >
							<div>
								<el-input v-model="baseGoodsForm.buyAmount" placeholder="件数" @change="amountChange"
									style="width: 180px;margin-right: 15px;"></el-input>件
							</div>

            </div>
					</el-form-item>

          <el-form-item label="拍下价格："  prop="payablePrice">
            <div style="display: flex;justify-content: space-between;">
              <div>
                <el-input v-model="baseGoodsForm.payablePrice" placeholder="请输入拍下价格" style="width: 180px;margin-right: 15px;">
                </el-input>元
              </div>

              <div>
                <el-button plain @click="addDependents" v-if="rules.attachFlag == 1">
                  <i class="el-icon-plus"></i>添加附加商品
                  <span v-if="dependentsFormList.length != 0">
										（{{dependentsFormList.length}}）
									</span>
                </el-button>
              </div>
            </div>
          </el-form-item>
		
				</el-form>
<!--				<div style="text-align: center;margin: 20px 0;color: red" @click="BaseGoodsValidator">&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;验证主商品Form&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;</div>-->
				<append-goods
            :rules="rules"
            ref="appendGoods"
            :cacheDependentsFormList="cacheDependentsFormList"
            :shopId="shopId"
            @attGoodsValidateSuccess="attGoodsValidateSuccess"
            v-if="rules.attachFlag">
        </append-goods>
<!--				<div class="isTaoke">-->
<!--					<span>该宝贝是否加入淘宝客推广计划： </span>-->
<!--					<span>-->
<!--						<el-radio v-model="taskTwoForm.istaoke" label="1">否</el-radio>-->
<!--						<el-radio v-model="taskTwoForm.istaoke" label="2">是（建议商家降低推广商品的淘客推广计划）</el-radio>-->
<!--					</span>-->
<!--				</div>-->
			</div>
		</el-card>
		<goods-list-model ref="goodsListModel" @check="baseCheck" ></goods-list-model>
	</div>
</template>

<script>
	// beforeCreate created beforeMount mounted 都不会触发。
	import GoodsListModel from '@/views/goodsManagement/goodsListModel';
	// import SbUpload from '@/components/upload/upload.vue';
	import AppendGoods from '@/views/taskone/component/appendGoods.vue';
	import UploadWaterImg from './uploadWaterImg';
	import {isInt, price} from "../../../tools/validate";
	const baseGoodsForm =  {
		// 主宝贝数据
    goodsLink:"",
    goodsId:"",
    goodsNo:"",
		goodsTitle:"",

		goodsPicture:"",
		watermarkFlag:"",
		watermarkPicture:"",

		displayPrice:"",
		payablePrice:"",
		goodsSeamen:'',
    unitPrice:'',
		buyAmount:1,
		// 其他

	};
	export default {
		data() {
			return {
        issPlatformRules:true,
				baseGoodsForm,
				baseGoodsFormRules: {
					goodsLink:[
						{required:true,message:'请输入商品链接',trigger:['blur']}
					],
					goodsTitle:[
						{required:true,message:'请输入商品名称',trigger:['blur']}
					],
					goodsPicture:[
						{required:true,message:'请上传商品主图',trigger:['change']}
					],
					goodsSeamen:[
						{required:true,message:'颜色规格尺寸',trigger:['blur']}
					],
          unitPrice:[
            {required:true,validator:price,trigger:['blur']}
          ],
					displayPrice:[
						{required:true,validator:price,trigger:['blur']}
					],
					payablePrice:[
						{required:true,validator:price,trigger:['blur']}
					],
					buyAmount:[
						{required:true,validator:isInt,trigger:['blur']}
					],
				},
				dependentsFormList:[],
        cacheDependentsFormList:[],
				goods:{},
        time:null,
			}
		},
		props: {
      isShowDate:{

      },
      rules:{
        type:[Object]
      },
			shopId:{
				type:[String,Number]
			},
			platformId:{
				type:[String,Number]
			},
      cacheForm:{
				type:[Object]
			},
		},
    methods: {
      unitPriceChange(unitPrice){
        this.baseGoodsForm.payablePrice = unitPrice * this.baseGoodsForm.buyAmount;
      },
      amountChange(amount){
        this.baseGoodsForm.payablePrice = amount * this.baseGoodsForm.unitPrice;
      },
			attGoodsValidateSuccess({att}){
				this.goods.att = att;
			},
			validateAll(){
				//验证主商品 和 附属商品 的FORM表单
        let isAppendGoods = this.rules.attachFlag==0||this.$refs.appendGoods.validate();
				const flag = this.BaseGoodsValidator()&&isAppendGoods;
				if(flag){
					this.goods.baseGoods = this.baseGoodsForm;
					this.$emit('successValidate',this.goods)
				}
				return flag
			},
			BaseGoodsValidator(){
				let flag = false;
				this.$refs.baseGoodsForm.validate((validator)=>{
					flag  = validator
				});
				console.log('基础商品数据验证',flag)
				return flag
			},
			handleSuccess2(res2){
				this.baseGoodsForm.watermarkFlag = 1;
				this.baseGoodsForm.watermarkPicture = res2.data.hash;
				console.log(res2.data.hash);
			},
			handleSuccess1(res1){
				this.baseGoodsForm.goodsPicture = res1.data[0].hash;
				this.baseGoodsForm.watermarkPicture = res1.data[1].hash;
				this.$refs.baseGoodsForm.validateField('goodsPicture')
			},
			automatic(){
				
			},
			handleSuccess: function(resp) {
				console.log('图片上传成功')
				console.log(resp)
			},
			baseCheck(row){
        console.log(row)
				//主宝贝数据赋值
				Object.keys(row).forEach(key=>{
					if(this.baseGoodsForm[key]!==undefined){
						this.baseGoodsForm[key] = row[key]
					}
				})
				this.$refs.baseGoodsForm.validate()

			},
			addDependents(){
        if(!this.issPlatformRules){
          return this.$mes({type:"warning",message:'该平台下不支持添加附属商品'})
        }
				this.$refs.appendGoods.addDependents()
			},
			norm: function() {
				console.log('跳转 基础佣金收费标准 页面')
			},
			baseChoiceBut: function() {
				console.log('选择商品');
				this.$refs.goodsListModel.open(this.shopId);
			},
		},
		components: {
			GoodsListModel,
			AppendGoods,
			UploadWaterImg,
		},
    beforeDestroy() {
      this.time = null;
      clearTimeout(this.time)
    },
    watch: {
      shopId(){
        if(this.isShowDate == false){
          this.$refs.baseGoodsForm.resetFields()
          this.$refs.appendGoods.resetFields()
          this.$refs.uploadWaterImg.clear()
          this.$refs.appendGoods.clear()
          this.baseGoodsForm.watermarkPicture = ''
        }else{
          this.time = setTimeout(()=>{
            clearTimeout(this.time)
            this.$emit('showStatus',false)
          },1500)
        }
      },
      rules(){

        const taskAttachGoodsVo = this.cacheForm.taskAttachGoodsVo?.concat()||[]
        if(this.rules.attachFlag == 1){
          if(taskAttachGoodsVo.length>this.rules.attachNum){
            taskAttachGoodsVo.splice(this.rules.attachNum)
            this.cacheDependentsFormList =taskAttachGoodsVo
          }else{
            this.cacheDependentsFormList =taskAttachGoodsVo
          }
        }
      },
      cacheForm(newV){
        if(!newV||!newV.baseGoods)return false;
        console.log(newV)
        Object.keys(this.baseGoodsForm).forEach(item=>{
          this.baseGoodsForm[item] = newV?.baseGoods[item]
        })

      },
			platformId() {
        console.log(this.platformId)
        //判断平台下可不可以添加附属商品
        const platformRulesIdList = this.rules.attachPlatform.split(',');
        this.issPlatformRules = platformRulesIdList.some(item=>item.indexOf(String(this.platformId))!=-1)

			}
		},
	}
</script>

<style lang="less" scoped>
	.linkText {
		color: @primaryColorQian;
		cursor: pointer;
		text-decoration: underline;
		margin-left: 50px;
	}
	.box-card {
		width: 980px;
		margin-bottom: 20px;
	}
	.isTaoke {
		margin-bottom: 45px;
	}
</style>
