<template>
	<div>
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>平台与任务类型</span>
			</div>
			<div class="shopcards">
				<div class="addShopName">
					<el-button plain @click="addShopModel"><i class="el-icon-plus"></i>添加店铺</el-button>
				</div>
				<el-form :model="taskOneForm" :rules="taskOneFormRules" ref="taskOneForm" label-width="150px"
					size="medium" class="demo-ruleForm">
					<el-form-item label="选择平台：" prop="platformId">
						<el-radio-group v-model="taskOneForm.platformId" @change="platformChange">
							<el-radio :label="item.platformId" v-for="(item,index) in platformList" :key="index">{{item.platformName}}
							</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="选择店铺：" prop="shopId">
						<el-radio-group v-model="taskOneForm.shopId" @change="shopNameChange"
							v-if="shopNameList.length != 0">
							<el-radio :label="item.shopId" v-for="(item,index) in shopNameList" :key="index" style="margin-top: 5px">{{item.shopName}}
							</el-radio>
						</el-radio-group>
						<span v-else style="color: red">请选择平台</span>
					</el-form-item>
					<el-form-item label="选择任务类型：" prop="typeCode">
						<el-radio-group v-model="taskOneForm.typeCode" @change="taskTypeChange" v-if="shopNameList.length != 0">
							<el-radio :label="item.typeCode" style="margin-bottom: 10px;" v-for="(item,index) in taskTypeList"
								:key="index">
								<span>{{item.typeName}}</span>
								<sb-toolstips v-if="item.des" :tip="item.des"></sb-toolstips>
							</el-radio>
						</el-radio-group>
						<span v-else  style="color: red">暂无该平台的任务类型</span>
					</el-form-item>
					<el-form-item label="选择返款模式：" prop="rebateMethod" v-show="false">
						<el-radio-group v-model="taskOneForm.rebateMethod" @change="taskTypeChange" >
							<el-radio :label="item.rebateId" v-for="(item,index) in rules.rebateMethod" :key="index">
								<span>{{item.rebateName}}</span>
							</el-radio>
						</el-radio-group>
						<span style="margin-left: 30px;color: rgb(255, 113, 75);">如果需要开通商家自主返款请联系平台</span>
					</el-form-item>
          <el-form-item label="选择派单模式：" prop="patternType">
            <el-radio-group v-model="taskOneForm.patternType" @change="taskTypeChange" >
              <el-radio :label="item.patternId" v-for="(item,index) in rules.patternType" :key="index">
                <span>{{item.patternName}}</span>
              </el-radio>
            </el-radio-group>
          </el-form-item>

<!--					<el-form-item label-width="30px" prop="topCheck">-->
<!--						<el-checkbox v-model="taskOneForm.topCheck"> 是否优先审核任务？</el-checkbox>-->
<!--						<span style="color: #999;">（5元/个任务,平台优先审核此任务上线）</span>-->
<!--					</el-form-item>-->
<!--					<el-form-item label-width="30px" prop="addGiveMoney">-->
<!--						<div>-->
<!--							<el-checkbox v-model="taskOneForm.addGive"> 是否进行加赏将任务指定在任务列表优先展示？</el-checkbox>-->
<!--							<span style="color: #999;">（任务没人接或接不完时,建议加赏提示买家积极性,优先接你的任务）</span>-->
<!--						</div>-->
<!--						<div v-show="taskOneForm.addGive">-->
<!--							加赏 <el-input v-model="taskOneForm.addGiveMoney" style="width: 100px;margin: 0 5px;">-->
<!--							</el-input>-->
<!--							元/单-->
<!--						</div>-->
<!--					</el-form-item>-->

				</el-form>
			</div>
		</el-card>
<!--    <span @click="validateAll">&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;validate&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;</span>-->
		<shop-opeartion-model ref="goodsOpearModel" title="添加店铺"></shop-opeartion-model>
	</div>
</template>

<script>
	// beforeCreate created beforeMount mounted 都不会触发。
	import ShopOpeartionModel from '../../shopManagement/operationModel.vue';
	import SbToolstips from '../../../components/tooltip/tooltip.vue';
	import {SHOPEXPAND,PLATFORMSELECT,TASKTYPE} from '../../../api/base'


	export default {
		data() {
			return {
				taskOneForm: {
					platformId:'',
          shopId:'',
          typeCode:'',
					rebateMethod:'',
          patternType:'',
				},
				taskOneFormRules: {
					platformId:[
							{required:true,message:'请选择平台',trigger:['change']}
					],
          shopId:[
						{required:true,message:'请选择店铺名称',trigger:['change']}
					],
          typeCode:[
						{required:true,message:'请选择任务类型',trigger:['change']}
					],
					rebateMethod:[
						{required:true,message:'请选择返款类型',trigger:['change']}
					],
          patternType:[
            {required:true,message:'请选择派单模式',trigger:['change']}
          ],
				},
				ruleForm: {
					radio: 0,
					topCheck: false,
					addGive: false,
					addGiveMoney: '',
				},
				platformList: [],
				shopNameList: [],

				returnModelList: [
					// {
					// 	name: '平台返款(+2 元/单)',
					// 	id: '1',
					// 	disabled: false,
					// 	des: '商家自主返还任务本金，任务订单付款提交后24小时内返还本金给买家！商家仅充值佣金到平台，平台负责买家的佣金返还。(暂不支持家具，虚拟，服务等类目商家)'
					// },
				],
				taskTypeList: [],
			}
		},
		props: {
      cacheForm:{
      },
			rules:{
				type:Object,
				default:()=>{
					return {}
				}
			},
		},
		watch:{
      rules:{
        handler(){
          this.getPlatformList()
        }
      },
			'rules.rebateMethod':{
				handler(newVal){
					this.taskOneForm.rebateMethod = this.taskOneForm.rebateMethod || newVal[0].rebateId;
				},
			},
      'rules.patternType':{
        handler(newVal){
          this.taskOneForm.patternType = this.taskOneForm.patternType || newVal[0].patternId;
        },
      },
		},
		methods: {
			validateAll(){
				let flag = true;

				this.$refs.taskOneForm.validate((validator)=>{
					if(!validator){
						flag = validator
					}
				});
        console.log(flag)
        console.log(this.taskOneForm)
        if(flag)this.$emit('success',this.taskOneForm)
				return flag;
			},
			getPlatformList: function() {
        const platformRulesIdList = this.rules.platform.split(',');
				this.$axios.get(PLATFORMSELECT).then(resp=>{
					if(resp.code == 200){
            const platformList = resp.data||[];
            const filterList = platformList.filter(item=>{
              return platformRulesIdList.indexOf(String(item.platformId)) !== -1
            })
            console.log(this.cacheForm)
            if(this.cacheForm.taskVo){
              const taskVo = this.cacheForm.taskVo
              const isPlatform = filterList.some(item=>item.platformId ==taskVo.platformId)
              if(isPlatform){
                this.taskOneForm.platformId =taskVo.platformId;
                this.getShopNameList(taskVo.platformId,taskVo.shopId)
                this.getTaskTypeList(taskVo.platformId,taskVo.typeCode)
                this.$emit('platformChange',taskVo.platformId);
              }
            }
						this.platformList =filterList;
					}
				})
			},

			getShopNameList: function(platformId,shopId) {
				this.taskOneForm.shopId = '';
				this.$axios.get(SHOPEXPAND,{platformId}).then(resp=>{
					if(resp.code == 200){
						this.shopNameList = resp.data;
            const issShop = this.shopNameList.some(item=>item.shopId == shopId)
            if(issShop)this.taskOneForm.shopId = shopId;
            this.shopNameChange(shopId)
					}
				})
			},
			getTaskTypeList: function(platformId,typeCode) {
        console.log(platformId,typeCode)
				this.taskOneForm.taskType = '';
				this.$axios.get(TASKTYPE,{platformId}).then(resp=>{
					if(resp.code == 200){
						this.taskTypeList = resp.data;
            const issTypecode = this.taskTypeList.some(item=>item.typeCode == typeCode)
            if(issTypecode)this.taskOneForm.typeCode = typeCode;
					}
				})
			},
			taskTypeChange: function(id) {
				console.log('任务类型 改变')
				console.log(id)
			},
			platformChange: function(id) {
				this.$emit('platformChange',id);
				this.getShopNameList(id);
        this.taskOneForm.typeCode =''
        this.getTaskTypeList(id);
			},
			shopNameChange: function(id) {
				this.$emit('shopNameChange',id);
				console.log('店铺 改变')
				console.log(id)
			},
			addShopModel: function() {
				console.log('打开增加店铺的模态框')
				this.$refs.goodsOpearModel.open();

			},
		},
		components: {
			ShopOpeartionModel,
			SbToolstips,
		},

		mounted() {

		},

		computed: {
			// name() {
			// 	return this.data
			// }
		},

	}
</script>

<style lang="less" scoped>
	.shopcards {
		position: relative;
	
		.addShopName {
			position: absolute;
			z-index: 9;
			right: 0px;
			top: 0px;
		}
	}
	.pushTask {
		background-color: #f5f5f5;
		height: 122px;
		width: 860px;
		border-radius: 5px;
	
	}
	.box-card {
		width: 980px;
		margin-bottom: 20px;
	}
</style>
